<template>
  <li class="payItem" v-bind:class="classObject()">


    <div class="itemHead" v-on:click="toggleItemDesc" v-bind:class="{open:isOpen}">

      <div>{{item.pgPaysDate | timeFromXtoDateView}}</div>
<!--      <div>-->
<!--        <b :inner-html.prop="item.pgWeeklyPays | moneyFormat"></b>-->
<!--      </div>-->

      <p class="dropPointer">i</p>
    </div>


    <div v-show="isOpen" class="itemBody">
      <div class="descriptionData">
        <p class="stxt">Еженедельный платеж</p>
        <p :inner-html.prop="item.pgWeeklyPays | moneyFormat"></p>
      </div>
      <div class="descriptionData txtRAlign">
        <p class="stxt">Основной долг</p>
        <p :inner-html.prop="item.pgBasicDebt | moneyFormat"></p>
      </div>
      <div class="descriptionData txtRAlign">
        <p class="stxt">Начисленные проценты</p>
        <p :inner-html.prop="item.pgChargePercent | moneyFormat"></p>
      </div>
      <div class="descriptionData ">
        <p class="stxt">Остаток основного долга</p>
        <p :inner-html.prop="item.pgBasicDebtBalance | moneyFormat"></p>
      </div>
      <div class="buttonWrap">
        <button v-on:click="redirectToPayment" v-if="item.CheckPay != 0">Оплатить</button>
      </div>
    </div>
  </li>
</template>

<script>
  export default {
    name: "payItem",
    data() {
      return {
        isOpen: false,
        classOption:{
          paid:false,
          notFullyPaid:false,
          awaiting:false,
          debt:false,
          nextPayment:false,
          singlPage:false,
        }
      }
    },
    components: {
    },
    props: {
      item: {
        type: Object,
        default: {}
      },
      isLast:{
        type:Boolean,
        default: false,
      },
      todayDate:{
        type:String,
        default:''
      },
      viewType:{
        type:String,
        default:'singlPage',
      }
    },
    methods: {
      toggleItemDesc() {
        this.isOpen = !this.isOpen;
      },
      redirectToPayment() {
        this.$router.push('/payment/current');
      },
      classObject() {

        this.classOption.paid = this.item.CheckPay === 0;
        this.classOption.notFullyPaid = this.item.CheckPay === 2;
        this.classOption.awaiting= this.item.CheckPay === 1;
        this.classOption.debt = this.isDept();

          if(!this.classOption.paid && !this.classOption.debt && this.isNextPay()) {
            //не оплачен и не в просрочке и текущая дата меньше графика не более 7 дней , предполагаем текущий платеж
            this.classOption.awaiting = false;
            this.classOption.nextPayment = true;
            this.item.CheckPay = 4;
          }
        this.classOption.singlPage = this.viewType === 'singlPage';

        return this.classOption;
      },
      isDept(){
        let td = Date.parse(this.todayDate);
        let pd = Date.parse(this.item.pgPaysDate);
        let delta = (pd-td)/1000/60/60/24;
        return delta < 0 && this.item.CheckPay === 1;
      },
      isNextPay(){
        let td = Date.parse(this.todayDate);
        let pd = Date.parse(this.item.pgPaysDate);
        let delta = (pd-td)/1000/60/60/24;
        return delta >= 0 && delta <= 7;
      }
    },
    mounted(){
      if(this.isLast){
        this.$emit('lastitem',true);
      }
      if (window.outerWidth > 760 && this.viewType=='singlPage') {
        this.isOpen = true;
      }
    }
  }
</script>

<style scoped>
.itemHead{display: flex;align-items: center;justify-content: space-between;height: 10vw;cursor: pointer}
li { border-top:1px solid  #DFE0E1;position: relative;}
.progress{position: relative;height: 100%;width: 10vw;}
.progress:before{content: '';width: 4px;left:calc(50% - 2px);height: 100%;position: absolute;background: #DFE0E1;top:-50%;}
.awaiting .progress:before{}
.itemBody{position: relative;z-index: 1}
.progressWrap{width: 8.4vw;height: 8.4vw;border-radius: 8.4vw;position: absolute;z-index: 1;left:calc(50% - 4.2vw);top:calc(50% - 4.2vw);display: flex;align-items: center;justify-content: center;box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);}
.progressWrap svg{width: 4vw;}
.paid .progressWrap, .paid .progress:before{background-color: #43AC34}
.notFullyPaid .progressWrap, .notFullyPaid .progress:before{background-color: orange}
 .nextPayment .progress:before{background-color: #1990FE}
.nextPayment .progressWrap:after{content: '';width: 4vw;height: 4vw;border-radius:4vw;background: #1990FE}
.awaiting .progressWrap{background-color: white;box-shadow: inset 0px 0px 0px 4px #DFE0E1 ;}
.awaiting .progress:before{}
.nextPay .progressWrap{}
.nextPay .progress:before{}
.debt .progressWrap, .debt .progress:before{background-color: #FF303C;}
.debt .progressWrap > svg{fill:white;}
.awaiting.debt .progressWrap{box-shadow: inset 0px 0px 0px 0px #DFE0E1 ;}
.buttonWrap{padding: 4vw 0;}

.dropPointer{margin: 0;border: 1px solid #c2c2c2;width: 5vw;height: 5vw;text-align: center;border-radius: 5vw;}


  @media screen and (min-width: 760px) {
     .progress{width: 5vw}
     .progressWrap{width: 3.6vw;height: 3.6vw;left:calc(50% - 1.8vw);top:calc(50% - 1.8vw);}
     .progressWrap svg{width: 2.3vw;}
     .dropPointer{width: 2vw;height: 2vw}
    .itemHead{height: 5vw}
    .nextPayment .progressWrap:after{width: 2vw;height: 2vw;border-radius:2vw;}
    .singlPage .dropPointer{display: none}
    .singlPage .itemHead {justify-content: flex-start; pointer-events: none}
    .singlPage .itemHead > div, .singlPage  .itemBody > div {border-right: 1px solid #DFE0E1;height: 100%;display: flex;align-items: center;justify-content: center }
    .singlPage .itemHead > div:nth-child(1){width: 15vw;}
    .singlPage .itemHead > div:nth-child(2){display: none}

    .singlPage .descriptionData{padding: 0;display: flex;align-items: center;}
    .singlPage .descriptionData:after{display: none;}
    .singlPage .descriptionData p:first-child{display: none}
    .singlPage.payItem{font-size: 1.4vw;display: flex;align-items: center;justify-content: flex-start;text-align: center}
    .singlPage .layoutBody{padding: 2.4vw 0.5vw 4.5vw;}
    .singlPage .itemBody{display: flex;align-items: center;justify-content: flex-start;height: 5vw;}
    .singlPage .itemBody > div:nth-child(1){width: 15vw;}
    .singlPage .itemBody > div:nth-child(2){width: 15vw;}
    .singlPage .itemBody > div:nth-child(3){width: 15vw;}
    .singlPage .itemBody > div:nth-child(4){width: 15vw;}
    .singlPage .itemBody > div:nth-child(5){border-right: 0;}
    .singlPage .buttonWrap{}
    .singlPage .buttonWrap button{padding: 0vw 0.5vw;}
    .buttonWrap{padding: 0;}
  }
  @media screen and (min-width: 1200px) {
    .progress{width: 40px}
    .progressWrap{width: 30px;height: 30px;left:calc(50% - 15px);top:calc(50% - 15px);}
    .progressWrap svg{width: 20px;}
    .dropPointer{width: 20px;height: 20px;}
    .itemHead{height: 45px}

    .nextPayment .progressWrap:after{width:15px;height: 15px;border-radius:15px;}

    .singlPage .dropPointer{display: none}
    .singlPage .itemHead {justify-content: flex-start;}
    .singlPage .itemHead > div, .singlPage  .itemBody > div {border-right: 1px solid #DFE0E1;height: 100%;display: flex;align-items: center;justify-content: center}
    .singlPage .itemHead > div:nth-child(1){width: 176px;}
    .singlPage .itemHead > div:nth-child(2){width: 176px;}
    .singlPage .itemHead > div:nth-child(3){display: none}

    .singlPage .descriptionData{padding: 0;display: flex;align-items: center;}
    .singlPage .descriptionData:after{display: none;}
    .singlPage .descriptionData p:first-child{display: none}
    .singlPage.payItem{font-size: 15px;}

    .singlPage .itemBody{display: flex;align-items: center;justify-content: flex-start;height: 45px;}
    .singlPage .itemBody > div:nth-child(1){width: 176px;}
    .singlPage .itemBody > div:nth-child(2){width: 176px;}
    .singlPage .itemBody > div:nth-child(3){width: 176px;}
    .singlPage .itemBody > div:nth-child(4){width: 176px;}
    .singlPage .itemBody > div:nth-child(5){width: 176px; border-right: 0;}
    .singlPage .buttonWrap{}
    .singlPage .buttonWrap button{padding: 0vw 5px;}
  }
</style>
